// eslint-disable-next-line no-redeclare,no-unused-vars
function convertWhere(whereIn, param) {
  var where = $.extend(true, {}, whereIn);
  if (!where.converted && !param.simple) where.converted = [];
  $.each(where, function (key, value) {
    if (key == 'converted') return;
    var result = convertWhereValue(key, value, where, param);
    if (result === false) return;
    where[key] = result;
    if (where[key] == null) delete where[key];
  });

  if (param.break) return false;
  return where;
}
